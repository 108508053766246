<template>
  <div class="grid">
    <div class="pg-header">
      <div class="pg-back-button">
        <button class="btn-primary" @click="back">Tillbaka</button>
      </div>
      <h2 v-if="storageLocationId < 1">Ny lagerplats</h2>
      <h2 v-if="storageLocationId > 0">Editera lagerplats</h2>
    </div>
    <div v-if="myStorageLocation" class="pg-form">
      <div class="pg-form-group">
        <label class="pg-label">Lagerplats</label>
        <input ref="storage_location_name" v-model="myStorageLocation.storage_location_name" class="pg-input" type="text" />
      </div>
      <div class="pg-form-group">
        <label class="pg-label">Beskrivning</label>
        <input v-model="myStorageLocation.storage_location_description" class="pg-input" type="text" />
      </div>
      <br />
      <div class="pg-form-group">
        <button class="btn-primary" @click="saveMyStorageLocation">Spara</button>
      </div>
      <div v-if="storageLocationId > 0">
        <div class="pg-form-group">
          <button class="btn-danger" @click="deleteMyStorageLocation">Radera</button>
        </div>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'StorageLocation',
  props: {
    storageLocationId: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      search: '',
      showError: false,
      error: '',
      password: '',
      password2: '',
      myRoles: {},
      myStorageLocation: {
        storage_location_id: 0,
        storage_location_name: '',
        storage_location_description: ''
      }
    };
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    isAdmin: function () {
      return this.$store.getters.isAdmin;
    },
    isWorker: function () {
      return this.$store.getters.isWorker;
    },
    isSeller: function () {
      return this.$store.getters.isSeller;
    }
  },
  created() {},
  async mounted() {
    if (this.storageLocationId > 0) {
      await this.fetchMyStorageLocation();
    } else {
      this.$refs.storage_location_name.focus();
    }
  },
  methods: {
    ...mapActions({
      fetchStorageLocation: 'storage-locations/fetchStorageLocation',
      addStorageLocation: 'storage-locations/addStorageLocation',
      updateStorageLocation: 'storage-locations/updateStorageLocation',
      deleteStorageLocation: 'storage-locations/deleteStorageLocation'
    }),
    async deleteMyStorageLocation() {
      const result = confirm(`Är du säker att du vill radera ${this.myStorageLocation.storage_location_name}?`);
      if (result) {
        let response = null;
        try {
          let data = {
            storage_location_id: this.storageLocationId
          };
          response = await this.deleteStorageLocation(data);
        } catch (error) {
          console.log(error);
          // TODO : FIX ERROR LOG
        } finally {
          let addUpdateOK = false;
          if (response.success == 1) {
            addUpdateOK = true;
          }
          this.$router.replace({
            name: 'StorageLocations',
            params: {
              addUpdateOK: addUpdateOK,
              hasAddUpdate: true,
              action: 'DELETE'
            }
          });
          // console.log("We do cleanup here");
        }
      }
    },
    back: function () {
      this.$router.replace({
        name: 'StorageLocations',
        params: {
          addUpdateOK: false,
          hasAddUpdate: false,
          action: ''
        }
      });
    },
    async fetchMyStorageLocation() {
      try {
        this.myStorageLocation = [];
        let data = {
          storage_location_id: this.storageLocationId
        };
        this.myStorageLocation = await this.fetchStorageLocation(data);
      } catch (error) {
        // console.log(error);
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              // console.error(err);
              // TODO : FIX ERROR LOG
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          }
        }
      } finally {
        // console.log("We do cleanup here");
      }
    },
    async saveMyStorageLocation() {
      let response = null;

      try {
        let data = {
          storage_location_id: this.myStorageLocation.storage_location_id,
          storage_location_name: this.myStorageLocation.storage_location_name,
          storage_location_description: this.myStorageLocation.storage_location_description
        };

        if (this.storageLocationId > 0) {
          response = await this.updateStorageLocation(data);
        } else {
          response = await this.addStorageLocation(data);
        }
      } catch (error) {
        // console.log("saveMyStorageLocation ERROR");
        // console.log(error);
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              //  console.error(err);
              // TODO : FIX ERROR LOG
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          }
        }
      } finally {
        let addUpdateOK = false;

        if (response && response.success == 1) {
          addUpdateOK = true;

          this.$router.replace({
            name: 'StorageLocations',
            params: {
              addUpdateOK: addUpdateOK,
              hasAddUpdate: true,
              action: 'SAVE'
            }
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
